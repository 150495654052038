import React from 'react'
import styled from 'styled-components'
import { darken } from 'polished'
import { CarouselProvider, Slider, Slide, DotGroup } from 'pure-react-carousel';
import Heading from '../blocks/heading';
import Image from '../blocks/image';
import SEO from '../layout/seo';
import { useStaticQuery, graphql } from "gatsby"
// import 'pure-react-carousel/dist/react-carousel.es.css';


const RefSection = styled.section`
    /*background-color: ${props => darken(0.25, props.theme.colors.primaryColor) || "red"};*/
    background-color: whitesmoke;
    color: black;

    .inner {
        padding: 8rem 0 7rem;
        @media (max-width: 799px) {
            max-width: 100%!important
        }
    }

`

const StyledText = styled.div`
    max-width: 35rem;
    margin-left: auto;
    margin-right: auto;
    text-align: center;

    h2 {
        position: relative;

        &::after {
            content: '”';
            position: absolute;
            display: block;
            font-family: serif;
            font-size: 20rem;
            font-weight: bold;
            top: -120%;
            right: -10%;
            opacity: 0.04
        }
    }
`

const StyledSlider = styled(Slider)`
    width: 100%;
    max-width: 600px;
    margin: 2rem auto 1em;
    @media (min-width: 800px) {
        margin: 4rem auto 2em;
    }
    text-align: center;

    .innerSlide {
        display: flex;
        flex-direction: column; 
        justify-content: center;
    }

    h4 {
        font-size: 1.3rem;
    }

    li {
        opacity: 1;
        transition: opacity .3s ease;

        &.hidden {
            opacity: 0
        }

    }

`

const ControlGroup = styled.div`
    display: flex;
    justify-content: space-between;

    & > button {
        border: 0;
        background-color: transparent;
        color: black;
        font-weight: bold;
        text-transform: uppercase;
    }
`

const StyledDotGroup = styled(DotGroup)`
    display: flex;
    justify-content: space-between;
    align-items: center;

    button {
        height: 30px;
        width: 30px;
        border: 0;
        background: transparent;
        opacity: 0.3;
        transition: opacity 0.3s ease;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        span {
            display: block;
            border-radius: 20px;
            background: black;
            height: 3px;
            width: 15px;
        }

        &.carousel__dot--selected {
            opacity: 1
        }
    }
`

const RefAvatar = styled.div`
    border-radius: 100%;
    height: 6rem;
    width: 6rem;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    margin: 1rem auto;
    border: 5px solid #d22e26;

    img, .gatsby-image-wrapper {
        min-width: 5rem;
        min-height: 5rem;
        position: absolute;
        max-width: 10rem;
        max-height: 10rem;
    }

    .gatsby-image-wrapper {
        width: 100%;
        height: 100%;
    }
`

const SmallerHeading = styled(Heading)`
    @media (max-width: 500px) {
        width: 80%;
        margin: auto;
    }
`

const Reference = (props) => {
    const data = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    domain
                    }
                }
            }
        `)
    const domain = data.site.siteMetadata.domain
    const {title, text, reference = [], Text, Titulek, klasickaSekceVelikost, Elementy} = props
    const elementData = Elementy || reference

    return(
        <RefSection className="full-width" id="reference">
            <SEO
                schema={true}
                data={{
                    aggregateRating: {
                        '@type': 'AggregateRating',
                        reviewCount: elementData.length,
                        ratingValue: 5,
                        itemReviewed: {
                            '@type': "Thing",
                            sameAs: `//www.${domain}.cz`,
                            name: domain
                        }
                    },
                    reviews: [...elementData.map(e => ({
                        '@type': 'Review',
                        itemReviewed: {
                            '@type': "Thing",
                            sameAs: `//www.${domain}.cz`,
                            name: domain
                        },
                        reviewBody: e.Text || e.text,
                        author: {
                            '@type': 'Person',
                            name: e.Titulek ? e.Titulek.split(",")[0] : e.name?.split(",")[0],
                            jobTitle: e.Titulek ? e.Titulek.split(",").slice(1).join(", ") : e.name?.split(",").slice(1).join(", "),
                            image: e.Obrazek ? e.Obrazek.url : e.img
                        }
                    }))]
                }}
                type={"Review"}
            />
            <div className="inner">
                <StyledText>
                    {(title || Titulek) && <SmallerHeading size={klasickaSekceVelikost || "h2"}>{title || Titulek}</SmallerHeading>}
                    {(Text || text) && <p>{Text || text}</p>}
                </StyledText>
                <CarouselProvider
                    naturalSlideWidth={50}
                    totalSlides={elementData.length}
                    infinite={true}
                    isPlaying={true}
                    isIntrinsicHeight={true}
                >
                    <StyledSlider>
                        {elementData.map((r, i) => (
                            <Slide key={i} classNameHidden="hidden" index={i} innerClassName={"innerSlide"}>
                                <article>
                                    {r.img && <RefAvatar><img src={r.img} alt={r.Titulek || r.name}/></RefAvatar>}
                                    {r.Obrazek && <RefAvatar><Image data={r.Obrazek.urlSharp.childImageSharp} alt={r.Titulek || r.name}/></RefAvatar>}
                                    <p>{r.Text || r.text}</p>
                                    <h4>{r.Titulek || r.name}</h4>
                                </article>
                            </Slide>
                        ))}
                    </StyledSlider>

                    <ControlGroup style={{display: 'flex', justifyContent: 'center'}}>
                        {/*<ButtonBack>Zpet</ButtonBack>*/} 
                        <StyledDotGroup></StyledDotGroup> 
                        {/*<ButtonNext>Dalsi</ButtonNext>*/}
                    </ControlGroup>

                </CarouselProvider>
            </div>
        </RefSection>
    )
}

export default Reference