import React from 'react'
import styled from 'styled-components'
import Heading from '../blocks/heading'
import Image from '../blocks/image'
import SEO from '../layout/seo'



const Section = styled.section`
    padding: 2rem 0 0;
    @media (min-width: 700px) {
        grid-gap: 2rem;
        display: grid;
        grid-template-columns: 2fr 3fr;
        padding: 4rem 0 0;
        align-items: center;
    }
`

const Area = styled.div`

    @media (max-width: 699px) {
        margin-top: 2rem;
    }

    article {
        text-align: center;
        max-width: 14rem;
        margin-left: auto;
        margin-right: auto;
        
        p {
            color: ${props => props.theme.colors.primaryColor};
            margin-top: 0.5rem;
        }
    }

    @media(min-width: 600px) {
        display: grid;
        justify-content: space-evenly;
        align-items: center;
        grid-gap: 3rem;
        grid-template-columns: 1fr 1fr 1fr;
    }

`

const InnerImageArticle = styled.article`
    @media(max-width: 599px) {
        width: 90%;
        height: ${props => 90 / props.ratio }%;
        margin-bottom: 2rem;
    }

    @media(min-width: 600px) {
        width: ${props => props.ratio * 4}rem;

        max-height: 4rem;
        position: relative;
        display: grid;
        align-items: center;
    }

    img {
        max-width: 100%
    }

`

const CooperationSection = (props) => {

    const {teams = [], title, Titulek, klasickaSekceVelikost, Elementy} = props
    const elementData = Elementy || teams

    return (
        <Section>
            {(title || Titulek) && <Heading size={klasickaSekceVelikost || "h3"}>{title || Titulek}</Heading>}
            <SEO 
                schema={true}
                type="Cooperation"
                data={elementData.map(e => ({
                    '@type': 'Organization',
                    name: e.Titulek || e.name,
                    image: {
                        '@type': 'ImageObject',
                        url: e.Obrazek?.url || e.img
                    }
                }))}
            />
            <Area>
                {elementData.map((t, i) => (
                    <InnerImageArticle key={i} ratio={t.Obrazek ? t.Obrazek.urlSharp.childImageSharp.fluid.aspectRatio : undefined}>
                        {t.img && <img src={t.img} alt={t.Titulek || t.name} />}
                        {t.Obrazek && <Image data={t.Obrazek.urlSharp.childImageSharp} alt={t.Titulek || t.name} objectFit={"contain"} />}
                    </InnerImageArticle>
                ))}
            </Area>
        </Section>
    )
}

export default CooperationSection