import React, { useState } from 'react'
import { Document, Page } from 'react-pdf'
import styled from 'styled-components'
import Loader from '../layout/loader'
import { FaFilePdf } from 'react-icons/fa/index.esm';


const ButtonGroup = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    background-color: white;
    transform: translateY(-50%);

    p {
        margin: 0 2rem;
    }

    button {
        height: 3rem;
        width: 3rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 0;
        background-color: white;
        transition: background-color 0.3s ease;

        &:not([disabled]):hover {
            background-color: whitesmoke;
        }
    }

`

const StyledPage = styled(Page)`
    .react-pdf__Page__canvas {
        max-width: 100%;
        position: relative;
        width: 100%!important;
        height: 100%!important;

        & > svg {
            width: 100%;
            height: 100%;
        }
    }

    .react-pdf__Page__annotations {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }
`

const StyledDocument = styled(Document)`
    max-width: 480px;
`

const PdfSection = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    transition: opacity 0.3s ease;

    & > div {
        box-shadow: 0px 4px 12px rgba(0,0,0,0.1);
    }

    &.loading {
        opacity: 0
    }
`

const PdfFile = ({file, width = 300}) => {

    const [currentPage, setCurrentPage] = useState(1)
    const [countPages, setCountPages] = useState(null)

    return (
        <>
            {!countPages && <Loader icon={ <FaFilePdf />}/> }
            <PdfSection className={!countPages ? "loading" : ""}>
                <StyledDocument file={file} onLoadSuccess={({numPages}) => {setCountPages(numPages)}}>
                    <StyledPage pageNumber={currentPage}/>
                </StyledDocument>
                <ButtonGroup>
                    <button onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 1}>&lt;</button>
                    <p>Stránka {currentPage} z {countPages}</p>
                    <button onClick={() => setCurrentPage(currentPage + 1)} disabled={currentPage === countPages}>&gt;</button>
                </ButtonGroup>
            </PdfSection>
        </>
    )
}

export default PdfFile