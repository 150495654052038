import React from 'react'
import GridTableSection from './gridTableSection'
import TeamMember from './teamMember'
import SEO from '../layout/seo'



const TymPrehled = ({Elementy}) => {

    const data = []

    Elementy.map((e, i) => (
        data.push({
            children: <TeamMember name={e.Titulek} image={e.Obrazek.urlSharp.childImageSharp}>{e.Text}</TeamMember>
        })
    ))

    return (
        <>
            <SEO type="SportsTeam" data={{
                sport: "swimming",
                athletes: Elementy.map(e => ({
                    name: e.Titulek,
                    image: e.Obrazek.url,
                    description: e.Text
                }))
            }} schema={true} />
            <GridTableSection 
                data={data} 
            />
        </>
    )
}

export default TymPrehled