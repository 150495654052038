import React from 'react'
import HeroAlternative from '../sections/heroAlternative'
import OfferCta from '../sections/offerCta';
import sliderSekceResolver from './sliderSekceResolver'
import Offer from '../blocks/offer';
import TymPrehled from '../sections/tymPrehled';

const blockResolver = (data, key) => {
    // console.log(data)
    switch(data.__typename) {
        case "Strapi_ComponentSpecialniSekceHeroSekce":
            return <HeroAlternative {...data} key={key}>{data.Text}</HeroAlternative>;
        case "Strapi_ComponentSekceTextMediaSekce":
            return <OfferCta {...data} key={key}></OfferCta>
        case "Strapi_ComponentSekceKlasickaSekce":
            return sliderSekceResolver(data, key);
        case "Strapi_ComponentSpecialniSekceSluzby":
            return <Offer {...data} key={key}></Offer>
        case "Strapi_ComponentSpecialniSekceTymPrehled":
            return <TymPrehled {...data} key={key}></TymPrehled>
        default:
            console.warn(`Missing block resolver of __typename: ${data.__typename}`, data)
            return
    }
}

export default blockResolver