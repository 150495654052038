import React from 'react'
import styled from 'styled-components'


const SectionGrid = styled.section`
    display: grid;
    grid-gap: 2rem;
    @media (min-width: 800px) {
        grid-gap: 3rem;
        grid-template-columns: 3fr 2fr;
    }
    @media (min-width: 1000px) {
        grid-template-columns: 1fr 1fr;
    }
    align-items: ${props => props.center ? "center" : ""};

    & > * {
        justify-self: center
    }
`

const TwoGrid = ({children, center}) => {
    return (
        <SectionGrid center={center}>
            {children}
        </SectionGrid>
    )
}

export default TwoGrid