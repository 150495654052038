import React from 'react'
import Loader from '../layout/loader'
import Img from 'gatsby-image'
import { MdImage } from 'react-icons/md/index.esm';


const Image = ({data, alt, objectFit}) => {
    // console.log(data)
    return (
        data !== null 
        ? typeof data === "object" 
            ? <Img fluid={data.fluid} alt={alt} objectFit={objectFit} />
            : <img src={data} alt={alt} />
        : <Loader icon={<MdImage />} /> 
    )
}

export default Image