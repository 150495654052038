import React from 'react'
import PdfFile from '../blocks/pdfFile'

const souborResolver = (data, key) => {
    switch(data.ext) {
        case ".pdf" :
            return <PdfFile file={data.url} width={350}  />
        default:
            console.log(`Missing souborResolver for datatype: ${data.ext}`)
            return
    }
}

export default souborResolver