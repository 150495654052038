import React from 'react'
import styled from 'styled-components'
import { CarouselProvider, Slider, Slide, Dot } from 'pure-react-carousel';
import Heading from '../blocks/heading';

const AboutSection = styled(CarouselProvider)`
    display: grid;
    grid-gap: 4rem;
    padding: 2rem 0;

    @media (min-width: 700px) {
        padding: 8rem 0;
        grid-template-columns: 3fr 2fr;
        grid-gap: 4rem;
        h2 {
            text-align: right;
        }
    }

`

const Dots = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
    
    @media(min-width: 700px) {
        align-items: flex-end;
    }

    button {
        border: 0;
        padding: 0.3rem 0;
        @media (min-width: 700px) {
            padding: 1rem 0;
        }
        background: transparent;
        display: flex;
        text-align: left;
        cursor: pointer;
        align-items: center;
        font-weight: 500;
    
        &::after {
            content: "";
            height: 1px;
            max-width: 100%;
            width: 5rem;
            margin-left: 1rem;
            display: block;
            position: relative;
            background-color: ${props => props.theme.colors.primaryColor};
            transition: transform 0.3s ease;
            transform: scaleX(0);
            transform-origin: left;
        }

        &[disabled]::after {
            transform: scaleX(1);
        }
    }
`

const StyledSlide = styled(Slide)`
    transition: opacity 0.3s ease, transform 0.3s ease;

    h3 {
        color: ${props => props.theme.colors.primaryColor};
    }

    &.hidden {
        opacity: 0;
        transform: scale(0.8);
    }

    .inner {
        display: flex;
        flex-direction: column;
        @media (min-width: 700px) {
            justify-content: center;
        }
    }
`

const StyledSlider = styled(Slider)`
    .carousel__slider-tray-wrapper {
        @media (min-width: 700px) and (max-width: 900px) {
            padding-bottom: 240%!important;
            li {
                padding-bottom: 240%!important;

            }
        }
        @media (max-width: 500px) {
            padding-bottom: 165%!important
        }
    }
`



const About = (props) => {

    const {title, items = [], Titulek, klasickaSekceVelikost, Elementy} = props

    const elementData = Elementy || items

    return (
        <AboutSection
            naturalSlideWidth={50}
            totalSlides={elementData.length}
            isPlaying={true}
            naturalSlideHeight={60} 
            orientation={"vertical"}  
        >
            <div>
                {(title || Titulek) && <Heading size={klasickaSekceVelikost || "h2"}>{title || Titulek}</Heading>}
                <Dots>
                    {elementData.map((it, i) => (
                        <Dot key={i} slide={i}>{it.Titulek || it.name}</Dot>
                    ))}
                </Dots>
            </div>
            
            <StyledSlider>
                {elementData.map((it, i) => (
                    <StyledSlide index={i} key={i} innerClassName="inner" classNameHidden="hidden">
                        <h3>{it.Titulek || it.name}</h3>
                        {it.Text || it.text}
                    </StyledSlide>
                ))}
            </StyledSlider>
        </AboutSection>
    )
}

export default About