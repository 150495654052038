import React, { useState } from 'react'
import YouTube from 'react-youtube'
import Loader from '../layout/loader'
import { FaYoutube } from 'react-icons/fa/index.esm';

const YouTubeBlock = ({videoId, opts}) => {
    const [loaded, setLoaded] = useState(false)
    return (
        <>
            <YouTube videoId={videoId} opts={opts} onReady={() => setLoaded(true)} />
            {!loaded && <Loader icon={ <FaYoutube />}/> }
        </>
    )
}

export default YouTubeBlock

