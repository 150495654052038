import React from 'react'
import styled from 'styled-components'
import { IconContext } from "react-icons";

const LoaderDesc = styled.div`
    background-color: rgba(0,0,0,0.05);
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9;
`

const Dots = styled.div`
    @keyframes moving {
        0% {
            transform: translateY(0);
        }
        10% {
            transform: translateY(-100%);
        }
        20% {
            transform: translateY(0);
        }
        30% {
            transform: translateY(80%);
        }
        40% {
            transform: translateY(0);
        }
    }

    display: flex;

    span {
        height: 10px;
        width: 10px;
        border-radius: 100%;
        background-color: rgba(0,0,0,0.2);
        margin: 0.3rem;
        animation: moving 1.6s infinite ease-out;
        animation-fill-mode: both;

        &:nth-child(2) {
            animation-delay: 0.1s
        }

        &:nth-child(3) {
            animation-delay: 0.2s
        }
    }
`

const Icon = styled.div`
    @keyframes opacity {
        from { opacity: 0.5 }
        to { opacity: 0.2 }
    }

    color: $primary;
    animation: opacity 1s infinite;
    animation-direction: alternate;
`


const Loader = ({icon}) => {
    return(
        <LoaderDesc>
            {icon
                ? <IconContext.Provider value={{ size: "3rem" }}><Icon>{icon}</Icon></IconContext.Provider>
                : <Dots>
                    <span></span>
                    <span></span>
                    <span></span>
                </Dots>
            }
            
        </LoaderDesc>
    )
}

export default Loader