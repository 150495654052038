import React from 'react'
import styled from 'styled-components'

const Grid = styled.section`
    display: grid;
    grid-gap: 2rem;

    @media (min-width: 500px) {
        grid-template-columns: 1fr 1fr ;
    }
    @media (min-width: 700px) {
        grid-template-columns: repeat(${props => props.count}, 1fr) ;

    }
`

const GridTableSection = ({count = 3, heading, text, data = []}) => {

    return(
        <section>
            {heading && <h2>{heading}</h2>}
            {text && <p>{text}</p>}

            <Grid count={count}>
                {data.map((d, i) => (
                    <React.Fragment key={i}>
                        {d.children}
                    </React.Fragment>
                ))}
            </Grid>
        </section>
    )
}

export default GridTableSection