import React from 'react'
import Link from '../layout/link'
import styled from 'styled-components'

const ButtonBlock = styled(Link)`
    border-radius: 5px;
    border: 1px solid ${props => props.black === "true" ? "black" : "white"};
    /* background: rgba(0,0,0,0.3); */
    padding: 0.6rem 2rem;
    justify-self: flex-start;
    text-decoration: none;
    color: inherit;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    transition: color 0.3s ease-out;
    font-weight: 500;


    &::before {
        content: "";
        border-radius: 100%;
        height: 230px;
        width: 0;
        background: ${props => props.black === "true" ? "black" : "white"};
        display: block;
        position: absolute;
        z-index: -1;
        transition: width 0.3s ease-out;
        opacity: 0.1;
    }

    &:hover {
        text-decoration: none;
        color: black!important;
        &::before {
            width: 120%;
        }
    }
`

const Button = ({children, className, link, ref, style, target, black}) => {

    return (
        <ButtonBlock to={link} className={`${className}`} ref={ref} style={style} target={target} black={black.toString()}>
            {children}
        </ButtonBlock>
    )
}

export default Button

const StyledLink = styled(Link)`
    color: ${props => props.theme.colors.primaryColor}!important;
    margin: 2rem 0;
    display: flex;
    align-items: center;
    font-weight: 500;
    cursor: pointer;
    
    &::after {
        content: "";
        height: 1px;
        max-width: 100%;
        width: 5rem;
        margin-left: 1rem;
        display: block;
        position: relative;
        background-color: ${props => props.theme.colors.primaryColor};
        transition: transform 0.3s ease;
    }

    &:hover::after {
        transform: translateX(1rem);
    }

`

export const ButtonCta = ({children, link, className, style, newTab = false}) => {

    return(
        <StyledLink to={link} className={className} style={{...style}} target={newTab ? "_blank" : null}>
            {children}
        </StyledLink>
    )
}