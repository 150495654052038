import React from 'react'
import styled from 'styled-components'
import Image from './image'
import TwoGrid from '../sections/twoGrid'
import ReactMarkdown from 'react-markdown'
import souborResolver from '../resolvers/souborResolver'
import SEO from '../layout/seo'
import { useStaticQuery, graphql } from "gatsby"
import YouTubeBlock from './youtube'

const Icon = styled.img`
    height: 4rem;
    margin-bottom: 1rem;
`

const IconGatsby = styled(Image)`
    height: 4rem;
    margin-bottom: 1rem;
`

const Article = styled.article`
    margin: 2rem auto 4rem;

    @media (min-width: 800px) {
        margin: 4rem auto 8rem;
    }

    h3 {
        max-width: 35rem;
        margin-bottom: 2rem;
    }
`

const Center = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    position: relative;
    overflow: hidden;

    iframe {
        display: block;
    }

    & > div {
        width: 100%;
    }
`

const StyledMd = styled(ReactMarkdown)`
    ul {
        padding-left: 1rem
    }
`

const Offer = (props) => {
    const data = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    domain
                    }
                }
            }
        `)
    const domain = data.site.siteMetadata.domain


    // console.log("offer", props)
    const {title, icon, children, Ikona, Titulek, Text, YouTubeVideo, Soubor} = props

    return (
        <Article>
            <SEO 
                schema={true}
                type="Offer"
                data={{
                    provider: {
                        '@type': 'Organization',
                        sameAs: `//www.${domain}.cz`
                    },
                    providerMobility: 'dynamic',
                    name: Titulek || title,
                    description: Text,
                    image: icon || Ikona.url
                }}
            />
            <TwoGrid>
                <div>
                    {(icon || (Ikona.url && !Ikona.urlSharp.childImageSharp)) && <Icon src={icon || Ikona.url} alt={Titulek || title} />}
                    {Ikona && Ikona.urlSharp.childImageSharp && <IconGatsby data={Ikona.urlSharp.childImageSharp} alt={Titulek || title} />}
                    <h3>{Titulek || title}</h3>
                    {Text && <StyledMd source={Text} />}
                </div>
                <Center>
                    {YouTubeVideo && <YouTubeBlock videoId={YouTubeVideo.YoutubeID} opts={{ width: "100%", height: 300 }} />}
                    {Soubor && souborResolver(Soubor, 1)}
                </Center>
            </TwoGrid>
            {children}

        </Article>
    )
}

export default Offer