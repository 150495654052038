import React from 'react'
import About from '../sections/about'
import Reference from '../sections/reference';
import TeamSlider from '../sections/teamHomepageSlider';
import CooperationSection from '../sections/cooperationSection';
import ImageSlider from '../sections/imageSlider';

const sliderSekceResolver = (data, key) => {
    switch(data.Typ) {
        case "o_nas":
            return <About {...data} key={key}></About>;
        case "reference": 
            return <Reference {...data} key={key}></Reference>
        case "tym":
            return <TeamSlider {...data} key={key}></TeamSlider>
        case "spoluprace":
            return <CooperationSection {...data} key={key}></CooperationSection>
        case "obrazky_titulek":
            return <ImageSlider {...data} key={key}></ImageSlider>
        default:
            console.warn(`Missing slider section resolver for typ: ${data.Typ}`, data)
            return
    }
}

export default sliderSekceResolver