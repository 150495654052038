import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { ButtonCta } from '../basic/button';
import Image from '../blocks/image';

const Section = styled.section`

    .inner {
        z-index: 999;
        width: 100%;
        position: relative;
        @media (max-width: 479px) {
            min-height: 70vh;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }
    @media (max-width: 479px) {
        min-height: 85vh;
    }
    min-height: 75vh;
    max-height: 85vh;
    overflow: hidden;
    color: black;

`

const SideImage = styled.div`
    z-index: 0;
    /* display: flex; */
    align-items: center;
    justify-content: center;
    overflow: hidden;
    margin-top: 0%;
    z-index: -1;
    width: 70%;
    position: relative;
    order: 1;
    margin-bottom: -38%;
    transform: translateX(-25%);
    min-height: 100%;
    
    @media (min-width: 480px) {
        transform: translateY(-65%);
        right: 10%;
        position: absolute;
        margin-bottom: unset;

    }


    @media (min-width: 700px) {

        width: 60%;
        /* transform: translateY(-50%); */
    }
    
    @media (min-width: 800px) {
        width: 50%;
        right: 0;
    }

    img {
        min-width: 110%;
        width: 110%;
        position: relative;
        /* filter: blur(2px); */
        min-height: 100%;
        transform: scale(1.01);
    }

    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(255,255,255,0.3);
        display: block
    }
`

const TextSection = styled.div`
    margin-bottom: -8rem;
    order: 2;
    h1 {

        font-size: 2.8rem;
        @media (min-width: 800px) {
            font-size: 4.5rem;
        }
        @media (min-width: 1000px) {
            font-size: 5.5rem;
        }
        max-width: 55rem;
        line-height: 1;
    }

    p {
        margin-top: 2rem;
        max-width: 25rem;
    }

    & > div {
        justify-self: flex-start
    }
`


const HeroAlternative = ({title, flip, children, image, Titulek, Obrazek, Odkaz}) => {

    const [imageData, setImageData] = useState(null)

    useEffect(() => {
        setImageData(Obrazek ? Obrazek.urlSharp.childImageSharp : image)
    }, [imageData, Obrazek, image])
    
    return (
        <Section className="full-width">
            <div className="inner">
                <TextSection>
                    <h1>{Titulek || title}</h1>
                    <p className="primary">{children}</p>
                    {Odkaz && <ButtonCta style={{display: 'inline-flex'}} black={true} link={Odkaz.Odkaz} newTab={Odkaz.NovaStranka && true}>
                        {Odkaz.Text}
                    </ButtonCta>}
                    
                </TextSection>
                <SideImage>
                    <Image data={imageData} alt={Titulek || title} />
                </SideImage>
            </div>
        </Section>
    )
}

export default HeroAlternative