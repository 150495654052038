import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import {ButtonCta} from '../basic/button'
import Heading from '../blocks/heading'
import Image from '../blocks/image'

const OfferSection = styled.section`

    display: grid;
    grid-gap: 4rem;
    margin-top: 5rem;
    padding: 2rem 0;
    align-items: center;

    .image {
        position: relative;
        overflow: hidden;
        min-height: 100%;
    }

    @media (min-width: 700px) {
        margin-top: 0;
        padding: 8rem 0 6rem;
        grid-template-columns: 3fr 2fr;
        grid-gap: 4rem;
    }

    img {
        max-width: 100%;
    }

`


const OfferCta = (props) => {

    const {title, text, image, Text, Media, OdkazTlacitka, TextTlacitka, Titulek, textMediaVelikost} = props

    const [imageData, setImageData] = useState(null)

    useEffect(() => {
        setImageData(Media ? Media.urlSharp.childImageSharp : image)
    }, [imageData, Media, image])

    return(
        <OfferSection>
            {imageData && <div className="image"><Image data={imageData} alt={title || Titulek} /></div>}
            <div>
                {(title || Titulek) && <Heading size={textMediaVelikost || "h2"}>{title || Titulek}</Heading>}
                {(text || Text) && <p>{text || Text}</p>}
                
                {OdkazTlacitka && TextTlacitka && <ButtonCta link={OdkazTlacitka}>{TextTlacitka}</ButtonCta>}
            </div>
        </OfferSection>
    )
}

export default OfferCta