import React, { useState } from 'react'
import styled from 'styled-components'
import Image from '../blocks/image'

const ImageBlock = styled.div`
    max-width: 100%;
    height: 15rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: whitesmoke;
    margin-bottom: 1rem;
    overflow: hidden;

    img {
        width: 100%;
        max-width: 100%;
    }

    .gatsby-image-wrapper {
        height: 100%;
        width: 100%
    }
`


const MemberImage = ({image, name}) => {
    return(
        <ImageBlock>
            <Image data={image} alt={name} />
        </ImageBlock>
    )
}

const MemberText = styled.p`
    overflow: hidden;
    position: relative;
    transition: max-height 0.3s ease;
    max-height: ${props => props.full ? "500rem" : "5rem" };

    &::after {
        height: 2rem;
        content: "";
        background: linear-gradient(to top, white, transparent);
        width: 100%;
        display: ${props => props.full ? "none" : "block"};
        position: absolute; 
        bottom: 0;
        transform: opacity 0.3s ease;
        opacity: ${props => props.full ? 0 : 1}
    }
`

const ToggleTextLink = styled.p`
    color: ${props => props.theme.colors.primaryColor};
`

const TeamMember = ({name, image, children}) => {

    const [showFull, setShowFull] = useState(false)


    return(
        <article onClick={() => setShowFull(!showFull)} onKeyDown={() => setShowFull(!showFull)} role="presentation">
            <h4>{name}</h4>
            {image && <MemberImage image={image} name={name}/>}
            <MemberText full={showFull}>{children}</MemberText>
            {!showFull ? <ToggleTextLink>Více</ToggleTextLink> : <ToggleTextLink>Méně</ToggleTextLink>}
        </article>
    )
}

export default TeamMember