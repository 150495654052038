import React from 'react'
import { CarouselProvider, Slider, Slide, Dot } from 'pure-react-carousel'
import styled from 'styled-components'
import Image from '../blocks/image'
import OfferCta from './offerCta'

const SliderSection = styled.section`
    position: relative;
    margin: 0 auto 5rem;
`

const CenterSlide = styled(Slide)`

    position: relative;

    @media (min-width: 1000px) {
        width: 80%;

    }

    .carousel__inner-slide {
    
        display: flex;
        align-items: center;
        justify-content: center;

        .gatsby-image-wrapper {
            height: 100%;
            width: 100%;
        }
    
        img {
            max-width: 100%;
            width: 100%;
            transform: scale(0.9)
        }
    }
`

const DotGroup = styled.div`
    right: 0;
    top: 50%;
    flex-direction: column;
    background: white;
    display: flex;
    align-items: flex-end;

    @media (min-width: 1000px) {
        padding: 2rem;
        transform:translateY(-50%);
        text-align: right;
        box-shadow: -10px 0px 11px -6px rgba(0,0,0,0.2);
        width: 40%;
        position: absolute;

    }
    
    button {
        border: 0;
        background: 0;
        padding: 0.4rem;
        margin: 0.3rem;
        text-align: right;
        position: relative;
        font-weight: 300;

        &::after {
            content: "";
            height: 1px;
            max-width: 100%;
            width: 5rem;
            display: block;
            position: absolute;
            background-color: ${props => props.theme.colors.primaryColor};
            transition: transform 0.3s ease;
            transform: translateX(-110%) translateY(-50%) scaleX(0);
            transform-origin: right;
            left: 0;
            top: 50%;
        }
        @media (max-width: 999px) {
            display: none
        }


        &[disabled]{
            &::after {
                @media(min-width: 1000px) {
                    transform: translateX(-110%) translateY(-50%) scaleX(1);
                }
            }
            display: block;
            @media (max-width: 999px) {
                width: 100%;
                text-align: center
            }
        }
    }
`

const StyledSlider = styled(Slider)`
    @media (min-width: 1000px) {
        width: 70%;

    }
`

const ImageSlider = (props) => {

    const {images = [], Elementy = [], klasickaSekceVelikost, Titulek, title} = props
    const imageData = Elementy.length > 0 ? Elementy : images

    return(
        <>
            {(title || Titulek) && <OfferCta textMediaVelikost={klasickaSekceVelikost || "h2"} Titulek={title || Titulek} />}
            <SliderSection>
                <CarouselProvider
                    totalSlides={imageData.length}
                    naturalSlideWidth={100}
                    infinite={true}
                    isPlaying={true}
                    naturalSlideHeight={60}
                    interval={5000}
                >
                    <StyledSlider>
                        {imageData.map((t, i) => (
                            <CenterSlide key={i} index={i} classNameHidden="hidden">
                                {t.image && <img src={t.image} alt={t.Titulek || t.text} />}
                                {t.Obrazek && <Image data={t.Obrazek.urlSharp.childImageSharp} alt={t.Titulek || t.text} objectFit={"contain"} />}
                            </CenterSlide>
                        ))}
                    </StyledSlider>


                    <DotGroup>
                        {imageData.map((t, i) => (
                            <Dot key={i} slide={i} >{t.Titulek || t.text}</Dot>
                        ))}
                    </DotGroup>
                </CarouselProvider>
            </SliderSection>
        </>
    )
}

export default ImageSlider