import React from 'react'
import styled from 'styled-components'
import { CarouselProvider, Slider, Slide } from 'pure-react-carousel'
import 'pure-react-carousel/dist/react-carousel.es.css';
import Heading from '../blocks/heading';
import Image from '../blocks/image';
import { ButtonCta } from '../basic/button';
// import { setLightness } from 'polished';


const TeamSection = styled.section`
    display: grid;
    grid-gap: 3rem;
    grid-template-columns: 100%;
    grid-template-areas: "text" "slider";
    padding: 4rem 0;
    align-items: center;
    
    @media (min-width: 700px) {
        padding: 8rem 0;
        grid-template-columns: 2fr 1fr;
        grid-template-areas: "slider text";
        grid-gap: 2rem;
    }
`

const StyledText = styled.div`
    grid-area: text;
    background-color: white;
    @media (min-width: 700px) {
        margin-left: -8rem;
        box-shadow: -10px 0px 11px -6px rgba(0,0,0,0.2);
        padding: 2rem;

    }
    z-index: 99;
`

const SliderContainer = styled.div`
    grid-area: slider;
`

const StyledSlide = styled(Slide)`
    width: 100%;
    position: relative;
    height: 0;
    padding: 25rem 0 0 0;
    overflow: hidden;
    transition: opacity 0.3s ease, transform 0.3s ease;
    
    .carousel__inner-slide {
        display: flex;
        align-items: center;

        .gatsby-image-wrapper {
            height: 100%;
            width: 100%;
        }
    }

    /* img, .gatsby-image-wrapper img {
        position: absolute;
        display: block;
        max-width: 100%;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
    } */


    &.hidden {
        opacity: 0;
        transform: scale(0.8);
    }
`



const TeamSlider = (props) => {

    const {title, text, team = [], Titulek, Text, textMediaVelikost, Elementy, Tlacitko : tlacitko} = props
    const elementData = Elementy || team

    return (
        <TeamSection>
            <SliderContainer>
                <CarouselProvider
                    totalSlides={elementData.length}
                    naturalSlideWidth={80}
                    infinite={true}
                    isPlaying={true}
                    naturalSlideHeight={10}
                    interval={3000}
                >
                    <Slider>
                        {elementData.map((t, i) => (
                            <StyledSlide key={i} index={i} classNameHidden="hidden">
                                {t.image && <img src={t.image} alt={t.Titulek || t.name} />}
                                {t.Obrazek && <Image data={t.Obrazek.urlSharp.childImageSharp} alt={t.Titulek || t.name} />}
                            </StyledSlide>
                        ))}
                    </Slider>
                </CarouselProvider>
            </SliderContainer>
            <StyledText>
                {(title || Titulek) && <Heading size={textMediaVelikost || "h2"}>{title || Titulek}</Heading>}
                {(text || Text) && <p>{text || Text}</p>}
                {tlacitko && tlacitko !== null && <ButtonCta link={tlacitko.Odkaz} newTab={tlacitko.NovaStranka} style={{marginBottom: 0}}>{tlacitko.Text}</ButtonCta>}
            </StyledText>
        </TeamSection>
    )
}

export default TeamSlider